/** @format */

import axios from 'axios'
import { restApi } from '../plugins/axios'
import i18n from '../i18n'
import store from '@/store'
import { encryptData } from '@/components/crypto_helpers'
import { getUserObj } from '@/components/auth_helpers'
import { sortModulList } from '@/components/moduladmin/ModulAdminFields.js'

async function login({ commit }, userData) {
  const backend = userData.backend
  let baseURL = '/'
  if (backend) {
    switch (backend) {
      case 'Fachbereich1':
        baseURL = '/VUE_APP_BE_ENDPOINT_FB1'
        break
      case 'Fachbereich2':
        baseURL = '/VUE_APP_BE_ENDPOINT_FB2'
        break
      case 'Fachbereich3':
        baseURL = '/VUE_APP_BE_ENDPOINT_FB3'
        break
      case 'Fachbereich4':
        baseURL = '/VUE_APP_BE_ENDPOINT_FB4'
        break
      default:
        baseURL = '/'
        break
    }
  }

  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios
      .create({
        baseURL,
        timeout: 10000,
      })
      // restApi
      .post('login/', {
        username: userData.username,
        password: userData.password,
      })
      .then((resp) => {
        const token = resp.data.jwt.access
        const refreshToken = resp.data.jwt.refresh
        const user = resp.data.user
        const role = resp.data.role
        const extra_roles = resp.data.extra_roles
        const role_configs = resp.data.role_configs

        localStorage.setItem('token', token)
        localStorage.setItem('refreshtoken', refreshToken)
        localStorage.setItem('user', encryptData(JSON.stringify({
          user,
          role,
          extra_roles,
          role_configs,
        })))
        localStorage.setItem('backend_endpoint', baseURL)
        localStorage.setItem('locale', i18n.global.locale ?? 'de')
        restApi.defaults.headers.Authorization = `Bearer ${token}`
        restApi.defaults.baseURL = baseURL

        commit('auth_success', { token, refreshToken, user })
        commit('tokenRefreshed', { status: false })
        resolve(resp)
      })
      .catch((err) => {
        const errorMessage = err.response && err.response.data.detail
        commit('auth_error', { errorMessage })
        localStorage.removeItem('token')
        reject(errorMessage)
      })
  })
}

export default {
  login,
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .post('logout/', {})
        .then((resp) => {
          commit('logout')
          localStorage.clear()
          delete restApi.defaults.headers.Authorization
          resolve(resp)
        })
        .catch((error) => {
          commit('logout')
          localStorage.clear()
          delete restApi.defaults.headers.Authorization
          reject(error)
        })
    })
  },
  refreshtoken({ commit }) {
    const refreshBody = { refresh: localStorage.getItem('refreshtoken') }

    restApi
      .post('/token/refresh/', refreshBody)
      .then((response) => {
        const token = response.data.access
        localStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization
          = `Bearer ${token}`
        restApi.defaults.headers.Authorization = `Bearer ${token}`
        commit('auth_success', { token })
      })
      .catch(() => {
        localStorage.removeItem('token')
        commit('tokenRefreshed', { status: true })
      })
  },
  getTableList({ commit }, tableName) {
    restApi
      .get(`/${tableName}`)
      .then((response) => {
        const tableList = response.data.Keys
        commit('setTableList', { tableList })
      })
      .catch(() => { })
  },
  updateTableItem({ _ }, tableData) {
    return new Promise((resolve, reject) => {
      const httpmethod = tableData.method // allows you to delete/update and change the request method type without hardcoding
      const token = localStorage.getItem('token')
      axios({
        url: `${tableData.endpoint}`,
        method: httpmethod,
        data: tableData.formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Version: store.getters.appVersion,
        },
        timeout: 60000,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStundenplanList({ commit }, payload) {
    commit('update_stundenplan_list', payload)
  },
  updatePAAntragsvorlagen({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get('paantragsvorlagen/', { timeout: 30000 })
      .then((response) => {
        const data = response.data?.data
        commit('update_paantragsvorlagen', data)
      })
      .catch(() => {})
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  updateMajorList({ commit }, payload) {
    commit('update_major_list', payload?.data)
  },
  updatePLs({ commit }, payload) {
    commit('loadingStatus', true)
    const searchKey = payload?.searchKeyword ?? ''
    let url = 'pls/'.concat(`?search=${searchKey}`)

    if (payload?.majorId)
      url = url.concat(`&studiengang_id=${payload?.majorId}`)

    if (payload?.modulId)
      url = url.concat(`&modul_id=${payload?.modulId}`)

    restApi
      .get(url)
      .then((response) => {
        const data = response.data?.data
        commit('update_pl_list', data)
      })
      .catch(() => {})
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  updateMainTopics({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get('schwerpunkte/')
      .then((response) => {
        const data = response.data?.data
        commit('update_main_topics', data)
      })
      .catch(() => {})
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  updateModulList({ commit }, payload) {
    commit('loadingStatus', true)

    let url = 'moduls/'.concat(`?search=${payload?.searchKeyword}`)
    if (payload?.modulId !== '')
      url = url.concat(`&studiengang_id=${payload?.modulId}`)

    restApi
      .get(url)
      .then((response) => {
        const data = response.data?.data
        commit('update_modul_list', data)
      })
      .catch(() => {})
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  updatePOs({ commit }, payload) {
    commit('update_pos', payload)
  },
  updateNotifications({ commit }) {
    restApi
      .get('notifications/', { timeout: 30000 })
      .then((response) => {
        const data = response.data
        commit('update_notifications', data)
      })
      .catch(() => { })
  },
  updateProfileSettings({ commit }, payload) {
    let prevObj = payload.profileSettings
    if (!prevObj)
      prevObj = {}

    if (payload.themecolor1)
      prevObj.themecolor1 = payload.themecolor1

    if (!prevObj.themecolor1)
      prevObj.themecolor1 = 'secondary'

    if (payload.themecolor2)
      prevObj.themecolor2 = payload.themecolor2

    if (!prevObj.themecolor2)
      prevObj.themecolor2 = '#90A4AE'

    if (payload.isDark)
      prevObj.isDark = true
    else
      prevObj.isDark = false

    if (payload.lang)
      prevObj.lang = payload.lang
    else
      prevObj.lang = 'de'

    restApi
      .post(`profilsettings/`, prevObj)
      .then((response) => {
        const settings = response.data
        commit('update_settings', settings)
      })
      .catch(() => { })
  },
  removeBelegung({ commit }, payload) {
    const stundenplanList = payload.stundenplanList
    const lv_id = payload.lv_id
    const zug = payload.zug
    let found = false
    for (let i = 0; i < stundenplanList.length && !found; i++) {
      const temp_lv_id = stundenplanList[i].LV.id
      const temp_zug = stundenplanList[i].Zug
      if (temp_lv_id === lv_id && temp_zug === zug) {
        found = true
        const updatedList = stundenplanList.splice(i, 1)
        commit('update_stundenplan_list', updatedList)
      }
    }
  },
  updatePruefungsformList({ commit }) {
    restApi
      .get(`pruefungsform_list/`)
      .then((response) => {
        const data = response.data.data

        commit('update_pruefungsform_list', { data })
      })
      .catch(() => { })
  },
  updateBewertungsschemaList({ commit }) {
    restApi
      .get(`bewertungsschema_list/`)
      .then((response) => {
        const data = [
          {
            id: null,
            Name: i18n.global.t('ModulAdmin.KeinBewertungsschema'),
            Code: '',
          },
          ...response.data?.data,
        ]
        commit('update_bewertungsschema_list', { data })
      })
      .catch(() => { })
  },
  updatePruefungsformTemplateList({ commit }) {
    restApi
      .get(`moduladmin/pruefungsformvorlagen/`)
      .then((response) => {
        const data = response.data.data

        commit('update_pruefungsformtemplate_list', { data })
      })
      .catch(() => { })
  },
  updateLanguageList({ commit }) {
    restApi
      .get(`sprache_list/`)
      .then((response) => {
        const data = response.data.data

        commit('update_language_list', { data })
      })
      .catch(() => { })
  },
  updateFachcodeList({ commit }) {
    restApi
      .get(`fachcode_list/`)
      .then((response) => {
        const data = response.data.data

        commit('update_fachcode_list', { data })
      })
      .catch(() => { })
  },
  updateLehrformList({ commit }) {
    restApi
      .get(`lehrform_list/`)
      .then((response) => {
        const data = response.data.data

        commit('update_lehrform_list', { data })
      })
      .catch(() => { })
  },
  updatePrueferList({ commit }) {
    restApi
      .get('moduladmin/pruefers/', { timeout: 60000 })
      .then((response) => {
        const data = response.data.data
        commit('update_pruefer_list', { data })
      })
      .catch(() => { })
  },
  updatePersonList({ commit }) {
    restApi
      .get('moduladmin/persons/', { timeout: 60000 })
      .then((response) => {
        const data = response.data.data
        commit('update_person_list', { data })
      })
      .catch(() => { })
  },
  updateSemesterList({ commit }) {
    restApi
      .get('semesters/')
      .then((response) => {
        const data = response.data.data
        commit('update_semester_list', { data })
      })
      .catch(() => { })
  },
  updateStudiengangList({ commit }) {
    restApi
      .get('moduladmin/studiengangs/')
      .then((response) => {
        const data = response.data.data
        commit('update_studiengang_list', { data })
      })
      .catch(() => { })
  },
  getPOModulList({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get(`pruefer/pomoduls/`, {
        timeout: '90000',
      })
      .then((response) => {
        const data = response.data.data
        commit('update_pomodul_list', { data })
        commit('loadingStatus', false)
      })
      .catch(() => { })
  },
  resetPOModulList({ commit }) {
    commit('reset_pomodul_list')
  },
  setPreventUnload({ commit }, payload) {
    commit('set_prevent_unload', payload)
  },
  getPrueferList({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get(`pruefer/`, {
        timeout: '90000',
      })
      .then((response) => {
        const data = response.data.data
        commit('update_pruefer_list', { data })
        commit('loadingStatus', false)
      })
      .catch(() => { })
  },
  updateCurrentSemesterInfo({ commit }) {
    restApi
      .get(`semesters/current/`)
      .then((response) => {
        const data = response.data.data
        commit('update_current_semester_info', { semesterInfo: data })
      })
      .catch(() => { })
  },
  updateNextSemesterInfo({ commit }) {
    restApi
      .get(`semesters/next/`)
      .then((response) => {
        const data = response.data.data
        commit('update_next_semester_info', { semesterInfo: data })
      })
      .catch(() => { })
  },
  toggleNav({ commit }) {
    commit('toggle_nav')
  },
  updateUserExtraRoles({ commit }, payload) {
    const objUser = getUserObj()
    localStorage.setItem('user', encryptData(JSON.stringify({
      user: objUser?.user,
      role: objUser?.role,
      extra_roles: payload?.extra_roles,
      role_configs: objUser?.role_configs,
    })))
    commit('update_user_extra_roles', payload)
  },
  updateBreadCrumbItems({ commit }, payload) {
    commit('update_breadcrumb_items', payload)
  },
  resetBreadCrumbItems({ commit }) {
    commit('reset_breadcrumb_items')
  },
  async getPOList({ commit }) {
    return new Promise((resolve, reject) => {
      restApi
        .get('moduladmin/pos/', { timeout: 90000 })
        .then((response) => {
          const data = response.data.data
          commit('update_moduladmin_po_list', { data })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getClearingEintragList({ commit }, payload) {
    commit('loadingStatus', true)

    let url = 'moduladmin/ces/'
    if (payload?.poId)
      url = `moduladmin/ces/?po_id=${payload.poId}&view=${payload.view}&show_closed=${payload.showClosed}`

    else if (payload?.modul_ids)
      url = `moduladmin/ces/?po_id=${payload.poId}&view=${payload.view}&modul_ids=${payload.modul_ids.join(',')}`

    else
      url = `moduladmin/ces/?view=${payload.view}&show_closed=${payload.showClosed}&without_comments=${payload.withoutComments}`

    return new Promise((resolve, reject) => {
      restApi
        .get(url, { timeout: 90000 })
        .then((response) => {
          commit('update_clearingeintrag_extra', {
            data: response.data.extra,
          })

          commit('update_clearingeintrag_list', {
            data: response.data.data,
            action: 'refresh',
          })
          commit('loadingStatus', false)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetClearingeintragList({ commit }) {
    commit('reset_clearingeintrag_list')
  },
  getModulList({ commit }, payload) {
    commit('loadingStatus', true)
    if (payload?.poId) {
      restApi
        .get(`moduladmin/moduls/?po_id=${payload.poId}`, {
          timeout: 60000,
        })
        .then((response) => {
          const data = sortModulList(payload?.poId, response.data.data)
          const poPermissions = response.data?.po_permissions
          const poStatus = poPermissions?.[0]?.Status ?? ''
          commit('update_clearingadmin_modul_list', { poId: payload?.poId, data })
          commit('update_clearingadmin_nav_list', {
            poId: payload?.poId,
            data,
          })
          commit('update_clearingadmin_clearingfiled_list', {
            poId: payload?.poId,
            data,
          })
          commit('update_clearing_po_permissions', { permissions: poPermissions })
          commit('update_clearing_po_status', { status: poStatus })
          store.dispatch('resetUpdatingStatus')
        }).finally(() => {
          commit('loadingStatus', false)
        })
    }
    else {
      restApi
        .get(`clearingadmin/moduls/`, {
          timeout: 60000,
        })
        .then((response) => {
          const data = response.data.data
          commit('update_clearingadmin_modul_list', { poId: payload?.poId, data })
        }).finally(() => {
          commit('loadingStatus', false)
        })
    }
  },
  updateHoverStatus({ commit }, payload) {
    commit('update_hover_status', payload)
  },
  resetClearingNavList({ commit }) {
    commit('reset_clearing_navlist')
  },
  resetAppBar({ commit }) {
    commit('reset_app_bar')
  },
  resetClearingList({ commit }) {
    commit('reset_clearing_list')
  },
  showSnackBar({ commit }, payload) {
    commit('show_snackbar', payload)
  },
  async updateClearingField({ commit }, payload) {
    commit('loadingStatus', true)

    if (payload?.action === 'update_field_dry')
      commit('updatingStatus', { isUpdating: true, modulId: payload?.modulId })

    return new Promise((resolve, reject) => {
      restApi
        .post('clearingadmin/moduls/', payload)
        .then((response) => {
          const data = response?.data?.data
          if (data?.updatedModulVersion) {
            store.dispatch('getModulList', payload)
            store.dispatch('getClearingEintragList', payload)
          }
          else {
            commit('update_clearing_field', payload)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          commit('updatingStatus', { isUpdating: false })
        })
        .finally(() => {
          commit('loadingStatus', false)
        })
    })
  },
  updateClearingeintragList({ commit }, payload) {
    commit('update_clearingeintrag_list', payload)
  },
  updateClearingnachrichtList({ commit }, payload) {
    commit('update_clearingnachricht_list', payload)
  },
  changeClearingModulListStart({ commit }, payload) {
    commit('change_clearing_modullist_start', payload)
  },
  resetUpdatingStatus({ commit }) {
    commit('updatingStatus', { isUpdating: false })
  },
  showOverlay({ commit }, payload) {
    commit('show_overlay', payload)
  },
  highlightClearingeintrag({ commit }, payload) {
    commit('hightlight_clearingeintrag', payload)
  },
  resetHighlightClearingeintrag({ commit }) {
    commit('hightlight_clearingeintrag', null)
  },
  deleteClearingeintrag({ commit }, payload) {
    commit('delete_clearingeintrag', payload)
  },
  deleteClearingnachricht({ commit }, payload) {
    commit('delete_clearingnachricht', payload)
  },
  updateFilterOptions({ commit }, payload) {
    commit('update_filter_options', payload)
  },
  resetFilterOptions({ commit }, payload) {
    commit('reset_filter_options', payload)
  },
  updateLVList({ commit }, payload) {
    commit('loadingStatus', true)
    const url = `ues/?search=${payload?.searchKeyword}`
    restApi
      .get(url)
      .then((response) => {
        const data = response.data
        const sortedList = data.sort((a, b) => {
          const nameA = a.spName ?? ''
          const nameB = b.spName ?? ''

          if (nameA > nameB)
            return 1

          if (nameA === nameB) {
            if (a.spZug > b.spZug) {
              return a.spPruefer > b.spPruefer
            }

            return a.spZug > b.spZug
          }

          return -1
        })

        commit('update_lv_list', sortedList)
      })
      .catch(() => {
      })
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  updateloadingStatus({ commit }, payload) {
    commit('loadingStatus', payload)
  },
  async updateMyStundenplan({ commit }, payload) {
    commit('loadingStatus', true)

    await restApi
      .post('mystundenplan/', payload)
      .then(() => {
        store.dispatch('getMyStundenplan')
      })
      .catch(() => { })
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  async getMyStundenplan({ commit }) {
    commit('loadingStatus', true)

    await restApi
      .get('mystundenplan/')
      .then((response) => {
        const data = response.data.data
        commit('update_stundenplan_list', data)
        store.dispatch('updateEventList', data)
      })
      .catch(() => { })
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  async updateEventList({ commit }, payload) {
    const Wochentag = {
      MO: 0,
      DI: 1,
      MI: 2,
      DO: 3,
      FR: 4,
      SA: 5,
    }
    const events = []
    const courses = []

    for (const sp of payload) {
      let startTime = new Date()
      if (sp.spTag) {
        const startDate = new Date(sp.spTag)
        const startDateText = startDate.toISOString().slice(0, 10)
        startTime = new Date(`${startDateText}T${sp.spBeginn}`)
      }
      else {
        const startDate = new Date()
        const startDateText = startDate.toISOString().slice(0, 10)
        const first = new Date(`${startDateText}T${sp.spBeginn}`)
        const diff = first.getDate() - first.getDay() + (first.getDay() === 0 ? -6 : 1)
        const startDayOfWeek = new Date(first.setDate(diff))
        startTime = new Date(
          startDayOfWeek.getTime()
          + Wochentag[sp.spWT] * 24 * 60 * 60 * 1000,
        )
      }
      const endTime = new Date(
        startTime.getTime() + sp.spDauer * 60 * 1000,
      )

      events.push({
        id: sp.spId,
        belegungId: sp.spBelegungId,
        lvId: sp.spLVId,
        title: `${sp.spName} | ${sp.spPruefer} | ${sp.spRaum}`,
        name: sp.spName,
        zug: sp.spZug ?? '',
        start: startTime,
        end: endTime,
        raum: sp.spRaum ?? '',
        dozent: sp.spPruefer,
        rrule: (sp.spTag ? '' : 'FREQ=WEEKLY;COUNT=25'),
        note: sp.spNote,
      })

      if (!courses.find(x => x.spLVId === sp.spLVId)) {
        courses.push({
          spLVId: sp.spLVId,
          spName: sp.spName,
          spPruefer: sp.spPruefer,
          spWT: sp.spWT,
          spZug: sp.spZug,
        })
      }
    }

    commit('update_event_list', events)
    commit('update_course_selection_list', courses)
  },
  async deleteBelegung({ commit }, payload) {
    commit('loadingStatus', true)

    await restApi
      .post('belegungen/', {
        course: { belegungId: payload.calendarEvent.belegungId },
        action: 'delete',
      })
      .then(() => {
        store.dispatch('getMyStundenplan')
      })
      .catch(() => { })
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  async updateBelegung({ commit }, payload) {
    commit('loadingStatus', true)

    await restApi
      .post('belegungen/', {
        course: { belegungId: payload.calendarEvent.belegungId },
        note: payload.note,
        action: 'update',
      })
      .then(() => {
        store.dispatch('getMyStundenplan')
      })
      .catch(() => { })
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  setExtraTitleInfo({ commit }, payload) {
    commit('set_extra_title_info', payload)
    document.title += ` ${store.getters.extraTitleInfo}`
  },
  resetExtraTitleInfo({ commit }) {
    commit('reset_extra_title_info')
  },
  getTicketMetaInfo({ commit }) {
    restApi
      .get(`/tickets/meta/`)
      .then((response) => {
        const data = response.data
        commit('update_ticket_meta_info', data.detail)
      })
      .catch(() => {
      })
  },
  getDokumentvorlagengruppeList({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get('dokumentvorlagengruppe_list/')
      .then((response) => {
        const data = response.data.data
        commit('get_dokumentvorlagengruppe_list', { data })
      })
      .catch(() => {
      }).finally(() => {
        commit('loadingStatus', false)
      })
  },
  updatePODokumentvorlagengruppe({ _ }, payload) {
    const poId = payload?.poId
    const dvgList = payload?.dvgList
    return new Promise((resolve, reject) => {
      restApi
        .post(`moduladmin/pos/${poId}/dokumentvorlagengruppe/`, dvgList)
        .then((resp) => {
          // const data = resp.data.data
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDokumentvorlagengruppe({ _ }, payload) {
    return new Promise((resolve, reject) => {
      restApi
        .get(`dokumentvorlagengruppe_list/${payload?.id}/`)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => { reject(err) })
    })
  },
  async downloadFile({ _ }, payload) {
    const url = `${payload?.area}/${payload?.id}/download/`

    return new Promise((resolve, reject) => {
      restApi
        .get(url, { responseType: 'blob' })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => { reject(err) })
    })
  },
  async deleteDokumentvorlage({ _ }, payload) {
    // remove in Dokumentvorlagengruppe when dvgId exists, otherwise remove in Dokumentvorlage
    const url = payload?.dvgId
      ? `/dokumentvorlagengruppe_list/${payload?.dvgId}/dokumentvorlage_list/${payload?.id}/`
      : `/dokumentvorlage_list/${payload?.id}/` //
    return new Promise((resolve, reject) => {
      restApi
        .post(url, {
          action: 'delete',
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => { reject(err) })
    })
  },
  updateDokumentvorlagengruppe({ _ }, payload) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`dokumentvorlagengruppe_list/`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDokumentvorlageList({ commit }) {
    commit('loadingStatus', true)

    restApi
      .get(`dokumentvorlage_list/`)
      .then((resp) => {
        const data = resp.data?.data ?? []
        commit('get_dokumentvorlage_list', { data })
      })
      .catch(() => {})
      .finally(() => {
        commit('loadingStatus', false)
      })
  },
  addDokumentvorlage({ _ }, payload) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`dokumentvorlagengruppe_list/${payload?.dvgId}/dokumentvorlage_list/`, payload?.formData, {
          timeout: 60000,
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveDokumentvorlage({ _ }, payload) {
    return new Promise((resolve, reject) => {
      restApi
        .post(`dokumentvorlage_list/`, payload?.formData, {
          timeout: 60000,
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDokumentvorlage({ _ }, payload) {
    return new Promise((resolve, reject) => {
      restApi
        .get(`dokumentvorlage_list/${payload?.id}/`)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => { reject(err) })
    })
  },
}
